import React from 'react'
import { Row, Container, Col } from "react-bootstrap";
import img1  from "../../assets/images/service1.png";
import img2  from "../../assets/images/service2.png";
const Service = () => {
    const servicelist = [
        {
          img: img1,
          heading: "Become an Instructor",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tet dolore magna facilisis.",
          btn:"Join Now"
        },
        {
          img: img2,
          heading: "Learning From Anywhere",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tet dolore magna facilisis. ",
          btn:"Get Started"
        },
      ];
  return (
    <>


<section className="servicebox sectionGrid">
        <Container>
          <Row>
            {servicelist.map((user) => (
              <Col sm={6} lg={6}>
                <div className="service">
                  <div className="left">
                    <h3>{user.heading}</h3>
                    <p>{user.text}</p>
                    <button className="btn-part">{user.btn}</button>
                  </div>
                  <div className="imgpart">
                    <img src={user.img} alt="img" />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Service