import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'



import "../scss/Maplist.scss"

import Header from "../component/common/Header";

import Footer from "../component/common/Footer"

const Maplist = () => {
  return (
    <>
        <Header/>
      <section className='maplist'>
        <Container>
            <Row>
                <Col lg={6} sm={6}>
                        <div className='leftpart'>
                            rjuet5i7i
                        </div>
                </Col>
            </Row>
        </Container>
      </section>  
      <Footer/>
    </>
  )
}

export default Maplist