import React from "react";

import About from "../component/home/About";

import Banner from "../component/home/Banner";
import Instructors from "../component/home/Instructors";
import Project from "../component/home/Project";
import Service from "../component/home/Service";
import StudentSay from "../component/home/StudentSay";

import Header from "../component/common/Header";
import Footer from "../component/common/Footer";

import "../scss/home.scss"

const Home = () => {
 
  return (
    <>
    <Header/>
      <Banner />
      <Project />

     <Service/>
     <About/>
     <Instructors/>
     <StudentSay/>
  <Footer/>
    </>
  );
};

export default Home;
