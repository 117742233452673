import React, { useState } from "react";
import google from "../assets/images/google.png";
import banner1 from "../assets/images/loginimage.jpg";
import { Form } from "react-bootstrap";
import {useNavigate,Link} from 'react-router-dom'
import "../scss/Login.scss";

const initialvalue = {
  email: "",
  pasword: "",
};
const Login = () => {
  var navigate=useNavigate()
  const [user, setUser] = useState(initialvalue);
  const { email, password } = user;
  const onValueChange = (e) => {
    console.log(e.target.value);
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handlesubmit = (event) => {
    event.preventDefault();
    console.log(user);
    navigate('/home')

  };
  return (
    <>
      <div
        className="login-bg"
        style={{
          background: `url(${banner1})`,
        }}
      >
        <div className="login-content d-flex justify-content-center h-100 d-inline-block">
          <div className="login-rimi">
            <div className="login-header">
              <div className="header-icon text-center">
                <a href="!">
                  <img alt="Google icon" src={google} />
                </a>
              </div>
              <div className="header-text">
                <h3>
                  LOGIN<span className="rimi">RiMi</span>
                </h3>
                <p className="text-muted">Access to our dashboard</p>
              </div>
            </div>
            <Form onSubmit={handlesubmit}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="email"> Email </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => onValueChange(e)}
                  value={email}
                  name="email"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="password">password </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="password"
                  onChange={(e) => onValueChange(e)}
                  value={password}
                  name="password"
                />
              </Form.Group>

              <div className="forgot-pw text-right">
                <a className="forgot-pw" href="!" alt="o">
                  <u>Forgot Password</u> ?
                </a>
              </div>
              <button type="submit" className="btn  btn-part w-100">Login</button>

              <p className=" have-anac text-center">
                Don't have an account? <Link to="/Registration">Register</Link>
              </p>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
