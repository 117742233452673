import React from 'react'
import {Row, Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import Logo from '../../assets/images/logo.png';
import {BrowserRouter as Router, Route, Link, Routes,NavLink } from 'react-router-dom';
import { FaFacebookF,FaYoutube,FaRegUser } from "react-icons/fa";

import "../../scss/style.scss";
const Header = (props) => {
  return (
   
    <>    
<header>
<Navbar bg="light" expand="lg">

        <Container>
        <Row>
        <Navbar.Brand href="#"><img src={Logo} alt="Logo"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className='menu'>
            <Nav
              className="me-auto my-2 my-lg-0">
              <Nav.Item>
                <Nav.Link as={Link} to={"/"}> Home</Nav.Link>
              </Nav.Item>
              {/* <Nav.Link href="#action2">Courses</Nav.Link> */}
              <NavDropdown title="Courses" id="navbarScrollingDropdown">
                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Item>
                <Nav.Link as={Link} to={"/"}>  Events </Nav.Link>
              </Nav.Item>
             
              <Nav.Item>
                <Nav.Link as={Link} to={"/"}> pages </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={"/"}>  Blog </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link as={Link} to={"/contact"}>  Contact us </Nav.Link>
              </Nav.Item>
            </Nav>
          
          </Navbar.Collapse>
          <Nav>
            <Nav.Link as={Link} to={"/"}>
              <FaFacebookF/>
              </Nav.Link>

              <Nav.Link as={Link} to={"/"}>
              <FaYoutube/>
              </Nav.Link>
              <Nav.Link as={Link} to={"/"}>
               <FaRegUser/>
              </Nav.Link>
            </Nav>
        </Row>
          
            
        </Container>
      </Navbar>
</header>


    </>

  )
}

export default Header