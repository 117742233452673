import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import tik  from "../../assets/images/tik.png";
import about  from "../../assets/images/about.png";
const About = () => {
  return (
    <>
      <section className="about">
        <Container>
          <Row>
            <Col sm={12} lg={6}>
              <h2>Get your tutor in an 
              around Milan</h2>
              
              <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidi
              dunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus 
              commodo viverra maecenas accumsan lacus vel facilisis. 
              </p>
              <ul>
                <li><img src={tik} alt="444"/> Lorem ipsum dolor sit amet</li>
                <li><img src={tik} alt="444"/>Lorem ipsum dolor sit amet</li>
                <li><img src={tik} alt="444"/>Lorem ipsum dolor sit amet</li>
              </ul>
              <button className="btn-part">More about us</button>
            </Col>

            <Col sm={12} lg={6}>
              <div className="about_images">
                  <img src={about} alt="about"/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default About;
