import React from "react";
import banner from "../../assets/images/banner.png";
import support from "../../assets/images/24h.png";
import {Row, Container, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaSearch } from "react-icons/fa";

const Banner = () => {
  return (
    <>

<section
        className="banner"
        style={{
          background: `url(${banner})`,
        }}
      >
        <Container>
      
        <Row>
        <Col lg={12}>
        <h6>. . . Welcome to RiMi</h6>
          <h1>More than 100 online courses</h1>
          <Form>
            <Form.Group className="form-group" controlId="">
              <Form.Select placeholder="Enter email">
                <option>Search Courses</option>
              </Form.Select>

              <Button type="submit">
                <FaSearch />
              </Button>
            </Form.Group>
          </Form>
          <div className="online_support">
            <ul className="d-flex">
              <li>
                <p>Lorem ipsum dolor sit amet, consecte-
                tur adipiscenas accumsan
                lacus vel
                facilisis.</p>
              </li>
              <li>
                <div className="time d-flex">
                  <img src={support} alt="24" />
                  <div className="colorflex">
                  <h5>ONLINE SUPPORT</h5>
                  <span>9874566123</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          </Col>
        </Row>
        
       
         
        </Container>
      </section>
    </>
  )
}

export default Banner