import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import img1 from "../../assets/images/expert1.png";
import img2 from "../../assets/images/expert2.png";
import { FiPlusCircle } from "react-icons/fi";
import { FaFacebookF, FaYoutube, FaTwitter } from "react-icons/fa";

const Instructors = () => {
  const expert = [
    {
      img: img1,
      name: "Z. A Watson",
      job: "Physics Teacher",
      university: "Bocconi University",
      courses:"100+ Courses"
    },
    {
      img: img2,
      name: "Z. A Watson",
      job: "Physics Teacher",
      university: "Bocconi University",
      courses:"100+ Courses"
    },
    {
      img: img1,
      name: "Z. A Watson",
      job: "Physics Teacher",
      university: "Bocconi University",
      courses:"100+ Courses"
    },
    {
      img: img2,
      name: "Z. A Watson",
      job: "Physics Teacher",
      university: "Bocconi University",
      courses:"100+ Courses"
    },
    {
      img: img1,
      name: "Z. A Watson",
      job: "Physics Teacher",
      university: "Domus University",
      courses:"100+ Courses"
    },
    {
      img: img2,
      name: "Z. A Watson",
      job: "Physics Teacher",
      university: "Domus University",
      courses:"100+ Courses"
    },
    {
      img: img1,
      name: "Z. A Watson",
      job: "Physics Teacher",
      university: "Bocconi University",
      courses:"100+ Courses"
    },
    {
      img: img2,
      name: "Z. A Watson",
      job: "Physics Teacher",
      university: "Domus University",
      courses:"100+ Courses"
    },
  ];
  return (
    <>
      <section className="instructor sectionGrid">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading">
                <h2>Expert Instructors</h2>
              </div>
            </Col>
            {expert.map((expert) => (
              <Col lg={3} sm={6}>
                <div className="expertbox">
                  <div className="imagepart">
                  <ul className="socal-icons">
                        <li><a href="f" target={"_blank"}><FaFacebookF/></a></li>
                        <li><a href="f" target={"_blank"}><FaYoutube/></a></li>
                        <li><a href="f" target={"_blank"}><FaTwitter/></a></li>
                    </ul>
                    <div className="icons">
                      <FiPlusCircle />
                    </div>
                    <div className="imgbox">
                      <img src={expert.img} alt="img" />
                    </div>
                                        
                    
                  </div>
                  <div className="heading-part">
                    <h5>{expert.name}</h5>
                    <h6>{expert.job}</h6>
                    <h6>{expert.university}</h6>
                    <span>{expert.courses}</span>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Instructors;
