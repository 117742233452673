import React from 'react'
import Header from "../component/common/Header";
import Footer from "../component/common/Footer";
const Contact = () => {
  return (
    <> 
      
    <Header/>
    <Footer/>
      
    </>
  )
}

export default Contact