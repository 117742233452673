import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import images1 from "../../assets/images/testimonila1.png";
import background from "../../assets/images/background.png";
import quite from "../../assets/images/quote.png";

const StudentSay = () => {
    //Owl Carousel Settings
const options = {
    loop:true,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 2,
        }
    },
  };
  return (
    <>
      <section className="testimonial">
        <div className="heading">
            <h2>What Our Students Say</h2>
        </div>
        <div
          className="clientSay"
          style={{
            background: `url(${background})`,
          }}
        >
          <Container>
            <Row>
              <Col lg={12}>
                <OwlCarousel {...options}
                  className="owl-theme"
                  loop
                  margin={40}
                >
                  <div class="item">
                    <h3>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida.
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <div className="student d-flex">
                      <img src={images1} alt="imahe" />
                      <div className="name">
                        <h5>Souza Fernandez Art</h5>
                        <span>Art</span>
                      </div>
                    </div>
                    <div className="quite">
                            <img src={quite}  alt="img" />
                    </div>
                  </div>
                  <div class="item">
                    <h3>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida.
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <div className="student d-flex">
                      <img src={images1} alt="imahe" />
                      <div className="name">
                        <h5>Souza Fernandez Art</h5>
                        <span>Art</span>
                      </div>
                    </div>
                    <div className="quite">
                            <img src={quite}  alt="img" />
                    </div>
                  </div>
                </OwlCarousel>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default StudentSay;
