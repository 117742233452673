import React from 'react'
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Home from  "../pages/Home";
import Contact from  "../pages/Contact";
import Maplist from  "../pages/Maplist";
import Registration from  "../pages/Registration";
import Login from '../pages/Login';

const Config = () => {
  return (
    <>


<Router>
   
        <Routes>
          <Route path='/'  element={<Login/>}/>
          {/* <Route path='/login' element={<Login/>}/> */}
          <Route path='/home' element={<Home/>}/>
          <Route path='/maplist' element={<Maplist/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/Registration' element={<Registration/>}/>
        
        </Routes>

      </Router>
    </>
  )
}

export default Config