import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import img1  from "../../assets/images/icons1.png";
import img2  from "../../assets/images/icons2.png";
import img3  from "../../assets/images/icons3.png";
import img4  from "../../assets/images/icons4.png";

const Project = () => {
  const projectlist = [
    {
      img: img1,
      heading: "Satisfied Students",
      number: "900+",
    },
    {
      img: img2,
      heading: "courses",
      number: "300+",
    },
    {
      img: img3,
      heading: "Instructors",
      number: "900+",
    },
    {
      img: img4,
      heading: "Activated Courses",
      number: "500+",
    },
  ];

  return (
    <>
      <section className="project">
        <Container>
        <div className="background">
          <Row>
            {
              projectlist.map((user) => (
                <Col sm={6} lg={3}>
              <div className="box d-flex">
                <div className="img-part"><img src={user.img} alt="oretuoi"/></div>
                <div className="headingpart">
                  <span>{user.number}</span>
                  <h3>{user.heading}</h3>
                </div>
              </div>
            </Col>
            ))
            }

            
          </Row>
          </div>
        </Container>
        
      </section>
    </>
  );
};

export default Project;
