import React, { useState } from "react";
import google from "../assets/images/google.png";
import banner1 from "../assets/images/office-desk.jpg";
import { Form } from "react-bootstrap";

import "../scss/Login.scss";

const Registration = () => {
  return (
    <>
      <div
        className="login-bg"
        style={{
          background: `url(${banner1})`,
        }}
      >
        <div className="login-content d-flex justify-content-center h-100 d-inline-block">
          <div className="login-rimi">
            <div className="login-header">
              <div className="header-icon text-center">
                <a href="!">
                  <img alt="Google icon" src={google} />
                </a>
              </div>
              <div className="header-text">
                <h3>
                Register<span className="rimi">RiMi</span>
                </h3>
                {/* <p className="text-muted">Access to our dashboard</p> */}
              </div>
            </div>
            <Form>
            <Form.Group className="mb-3">
                <Form.Label htmlFor="name"> Use name </Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Use name"
                  name="name"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="email"> Email </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="password">password </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="password"
                  name="password"
                />
              </Form.Group>
              <Form.Group className="mb-3">
              <Form.Label htmlFor="password">confirm password</Form.Label>
              <Form.Control
                  type="password"
                  placeholder="confirm password"
                  name="compassword"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <button className="btn  btn-part w-100">Submit</button>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
