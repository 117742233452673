

import "bootstrap/dist/css/bootstrap.min.css";

import Login from "./pages/Login";

import "./App.scss";
import Config from "./config/Config";
import Registration from "./pages/Registration.jsx";



function App() {

  return (
      <>
        <Config/>
      </>
  );
}

export default App;
