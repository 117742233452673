import React from "react";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import facebook from "../../assets/images/facebook.png";
import Instagram from "../../assets/images/instrogram.png";
import Twitter from "../../assets/images/tweeter.png";
import YouTube from "../../assets/images/youtube.png";
import footer from "../../assets/images/footer.png";
import { FiMapPin, FiPhone, FiMail } from "react-icons/fi";
const Footer = () => {
  return (
    <>
      <footer
        style={{
          background: `url(${footer})`,
        }}
      >
        <Container>
          <Row>
            <Col lg={12} sm={12}>
              <div className="footer-top">
                <ul className="d-flex">
                  <li>
                    <a href="!">
                      <img src={Logo} alt="trrr" />
                    </a>
                  </li>
                  <li>
                    <a href="f" target={"_blank"}>
                      <img src={facebook} alt="facebook" />
                      <span>Facebook</span>
                    </a>
                  </li>
                  <li>
                    <a href="f" target={"_blank"}>
                      <img src={Instagram} alt="Instagram" />
                      <span>Instagram</span>
                    </a>
                  </li>
                  <li>
                    <a href="f" target={"_blank"}>
                      <img src={Twitter} alt="facebook" />
                      <span>Twitter</span>
                    </a>
                  </li>
                  <li>
                    <a href="f" target={"_blank"}>
                      <img src={YouTube} alt="facebook" />
                      <span>YouTube</span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} sm={6}>
              <div className="link">
                <h3>Our Courses</h3>
                <ul>
                  <li>
                    <NavLink to={"/"}> Data Science</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Artificial Intelligence</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Graphic Design</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Cyber Security</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Data Analytics.</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Digital Marketing</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Social Media Marketing</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Programming</NavLink>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={3} sm={6}>
              <div className="link">
                <h3>Explore</h3>
                <ul>
                  <li>
                    <NavLink to={"/"}>About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Upcoming Events</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Blog & News</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>FAQ Question</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Testimonial</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Privacy Policy</NavLink>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={3} sm={6}>
              <div className="link">
                <h3>Useful Links</h3>
                <ul>
                  <li>
                    <NavLink to={"/"}>Contact Us</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Pricing Plan</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Instructor Profile</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>FAQ</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>Popular Courses</NavLink>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={3} sm={6}>
              <div className="link">
                <h3>Contact Info</h3>
                <ul>
                  <li>
                    <NavLink to={"/"}>
                      <FiMapPin />
                      P.za del Duomo, 2012 Milano MI, Italy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>
                      <FiPhone />
                      (+122) 123 123 777
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={"/"}>
                      <FiPhone />
                      (+122) 123 123 777
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to={"/"}>
                      <FiMail />
                      yourmail@example.com
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={12}>
              <div className="copyright">
                <h3>Copyright 2022 RiMi All Rights Reserved</h3>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
